import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Airplane mode",
    color: "#3772FF",
    images: "/images/icon-airplane.svg",
    content:
      "Need to relax and get away from it all? Headed out on a trip and want to keep yourself entertained and your brain active? Try single player.",
  },
  {
    title: "Multiplayer",
    color: "#9757D7",
    images: "/images/icon-vs.svg",
    content:
      "Face-off in three turn-based rounds, where each cube is bigger than the last! Battle to discover who is the biggest word nerd!",
  },
  {
    title: "Uncover Fun Shapes",
    color: "#EF466F",
    images: "/images/icon-egg.svg",
    content:
      "Uncover, explore, and discover fantastic scenes that are sure to make you smile. Collect some extra gold in 'Lexicube Shapes' which you can spend on power-ups and letters during gameplay.",
  },
  {
    title: "Coming soon",
    color: "#45B26B",
    images: "/images/icon-next.svg",
    content:
      "As we grow, we plan to unleash exciting new game modes such as 'Lexicube Rares', 'Lexicube Quests', and more. Stay tuned!",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Game Modes</h2>
          <div className={styles.info}>
            Basic gameplay is always free, but if you choose, you can  unlock and earn more through these fun new game modes!
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              {/* <div className={styles.number}>Step {index + 1}</div> */}
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Steps;
