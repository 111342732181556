import React from "react";
import styles from "./GameMode02.module.sass";
import Breadcrumbs from "./Breadcrumbs";
import Catalog from "./Catalog";
import Testimonials from "../../components/Testimonials";

const GameMode02 = () => {
  return (
    <>
      <Breadcrumbs />
      <Catalog />
      <Testimonials className="section-pb" />
    </>
  );
};

export default GameMode02;
