import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Subscription from "../Subscription";
import Theme from "../Theme";
import Icon from "../Icon";
import Image from "../Image";

const menu = [
  {
    title: "Apple App Store",
    url: "https://apps.apple.com/us/app/lexicube/id1437594872",
    image: "/images/menu-class.svg",
  },
  {
    title: "Google Play Store",
    url: "https://play.google.com/store/apps/details?id=com.division6.lexicube&hl=en_US&gl=US",
    image: "/images/menu-video.svg",
  },
];

const socials = [
  {
    title: "twitch",
    size: "16",
    url: "https://www.twitch.tv/lexicube",
  },
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com/lexicube/",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/lexicube",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/lexicube/",
  },
  {
    title: "Tiktok",
    size: "16",
    url: "https://dribbble.com/lexicube",
  },
  {
    title: "The Social Market",
    size: "20",
    url: "https://www.behance.net/lexicube",
  },
  {
    title: "Discord",
    size: "20",
    url: "https://www.behance.net/lexicube",
  },
  {
    title: "Twitch",
    size: "20",
    url: "https://www.behance.net/lexicube",
  },
];

const Footer = () => {
  const [visible, setVisible] = useState(false);

  return (
    <footer className={styles.footer}>
      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.col}>
            <div className={styles.box}>
              <Link className={styles.logo} to="/">
                <Image
                  className={styles.pic}
                  src="/images/logo-stacked.svg"
                  srcDark="/images/logo-stacked.svg"
                  alt="Lexicube"
                />
              </Link>
              {/* <Theme className={styles.theme} /> */}
            </div>

            <div className={styles.btns}>
              <div className={styles.button}>
                <a href="https://apps.apple.com/us/app/lexicube/id1437594872" target="_blank" rel="noopener noreferrer">
                  <Image
                    srcSet="/images/appstoreIcon.png"
                    srcSetDark="/images/appstoreIcon.png"
                    alt="Download from the Apple App Store"
                  />
                </a>
              </div>
              <div className={styles.button}>
                <a href="https://play.google.com/store/apps/details?id=com.division6.lexicube&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer">
                  <Image
                    srcSet="/images/googleplayIcon.png"
                    srcSetDark="/images/googleplayIcon.png"
                    alt="Download from Goolge Play"
                  />
                </a>
              </div>
            </div>
            
            {/* <div className={cn(styles.item, { [styles.active]: visible })}>
              <div
                className={styles.category}
                onClick={() => setVisible(!visible)}>
                footer nav
                <Icon name="arrow-bottom" size="9" />
              </div>
              <div className={styles.menu}>
                {menu.map((x, index) => (
                  <NavLink
                    className={styles.link}
                    activeClassName={styles.active}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                ))}
              </div>
            </div> */}
          </div>
          <div className={styles.col}>
            <div className={styles.category}>contact</div>
            <div className={styles.info}>
              <p>press@lexicube.com</p>
              <p>help@lexicube.com</p>
              <p>legal@lexicube.com</p>
              <p><a href="https://app.termly.io/document/privacy-policy/7a0bb183-c48b-475b-b0c0-242ecba50771">Privacy</a></p>
              <p><a href="https://app.termly.io/document/terms-and-conditions/527e0687-1f53-4be8-bda6-128d00eb212c">Terms of Use</a></p>
            </div>
          </div>
          {/* <div className={styles.col}>
            <div className={styles.category}>newsletter</div>
            <div className={styles.info}>
              Subscribe to our newsletter to keep up on all the new game modes.
            </div>
            <Subscription
              className={styles.subscription}
              placeholder="Enter your email"
            />
          </div> */}
        </div>
      </div>
      <div className={styles.foot}>
        <div className={cn("container", styles.container)}>
          <div className={styles.copyright}>
            Copyright © 2023 LEXICUBE INC. All rights reserved
          </div>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
