import React from "react";
import styles from "./GameMode03Details.module.sass";
import Hero from "./Hero";
import Interesting from "./Interesting";

const GameMode03Details = () => {
  return (
    <>
      <Hero />
      {/* <Interesting /> */}
    </>
  );
};

export default GameMode03Details;
