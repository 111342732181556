import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import Exploration from "./screens/Exploration";
import Mining from "./screens/Mining";
import Download from "./screens/Download";
import Privacy from "./screens/Privacy";
import Terms from "./screens/Terms";
import GameMode01 from "./screens/GameMode01";
import GameMode01Details from "./screens/GameMode01Details";
import GameMode02 from "./screens/GameMode02";
import GameMode02Details from "./screens/GameMode02Details";
import GameMode03 from "./screens/GameMode03";
import GameMode03Details from "./screens/GameMode03Details";
import GameMode04 from "./screens/GameMode04";
import GameMode04Details from "./screens/GameMode04Details";
import Tools from "./screens/Tools";
import Article from "./screens/Article";

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Page>
              <Home />
            </Page>
          )}
        />
        <Route
          exact
          path="/exploration"
          render={() => (
            <Page>
              <Exploration />
            </Page>
          )}
        />
        <Route
          exact
          path="/mining"
          render={() => (
            <Page>
              <Mining />
            </Page>
          )}
        />
        <Route
          exact
          path="/download"
          render={() => (
            <Page>
              <Download />
            </Page>
          )}
        />
        <Route
          exact
          path="/game-mode01"
          render={() => (
            <Page>
              <GameMode01 />
            </Page>
          )}
        />
        <Route
          exact
          path="/privacy"
          render={() => (
            <Page>
              <Privacy />
            </Page>
          )}
        />
        <Route
          exact
          path="/terms"
          render={() => (
            <Page>
              <Terms />
            </Page>
          )}
        />
        <Route
          exact
          path="/game-mode01-details"
          render={() => (
            <Page>
              <GameMode01Details />
            </Page>
          )}
        />
        <Route
          exact
          path="/game-mode02"
          render={() => (
            <Page>
              <GameMode02 />
            </Page>
          )}
        />
        <Route
          exact
          path="/game-mode02-details"
          render={() => (
            <Page>
              <GameMode02Details />
            </Page>
          )}
        />
        <Route
          exact
          path="/game-mode03"
          render={() => (
            <Page>
              <GameMode03 />
            </Page>
          )}
        />
        <Route
          exact
          path="/game-mode03-details"
          render={() => (
            <Page>
              <GameMode03Details />
            </Page>
          )}
        />
        <Route
          exact
          path="/game-mode04"
          render={() => (
            <Page>
              <GameMode04 />
            </Page>
          )}
        />
        <Route
          exact
          path="/game-mode04-details"
          render={() => (
            <Page>
              <GameMode04Details />
            </Page>
          )}
        />
        <Route
          exact
          path="/tools"
          render={() => (
            <Page>
              <Tools />
            </Page>
          )}
        />
        <Route
          exact
          path="/article"
          render={() => (
            <Page>
              <Article />
            </Page>
          )}
        />
      </Switch>
    </Router>
  );
}

export default App;
