import React from "react";
import cn from "classnames";
import styles from "./HeroVideo.module.sass";
// import Slider from "react-slick";
// import Icon from "../../components/Icon";
// import ScrollParallax from "../ScrollParallax";
import ReactPlayer from 'react-player'

const videoURL = "https://www.youtube.com/watch?v=5z47OhzskqU";

// const videos = [
//   {
//     url: "https://www.youtube.com/watch?v=WUX16ZUiXLE",
//     play: true,
//   },
//   {
//     image: "https://www.youtube.com/watch?v=WUX16ZUiXLE",
//     play: true,
//   },
//   {
//     image: "https://www.youtube.com/watch?v=WUX16ZUiXLE",
//     play: true,
//   },
//   {
//     image: "https://www.youtube.com/watch?v=WUX16ZUiXLE",
//     play: true,
//   },
// ];

const HeroVideo = () => {
  return (
    <div className={cn("section-video-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
      <h2 className={cn("h2", styles.title)}>Launch Trailer</h2>
        <div className={styles.video}>
        <ReactPlayer url={videoURL} />
        {/* <Slider className="workout-slider" >
          {videos.map((x, index) => (
            <div className={styles.slide} key={index}>
              <div className={cn("workout-item", styles.item)}>
                <div className={styles.preview}>
                  <ReactPlayer url={x.url} />
                  {x.play && (
                    <button className={cn("play", styles.play)}>
                      <Icon name="play" size="21" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </Slider> */}
        </div>
      </div>
    </div>
  );
};

export default HeroVideo;
