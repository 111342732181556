import React from "react";
import styles from "./GameMode04Details.module.sass";
import Hero from "./Hero";
import Interesting from "./Interesting";

const GameMode04Details = () => {
  return (
    <>
      <Hero />
      {/* <Interesting /> */}
    </>
  );
};

export default GameMode04Details;
