import React, { useState } from "react";
import { Link, NavLink, withRouter, useLocation } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./DropdownMenu.module.sass";
import headerstyles from "../Header.module.sass";
// import Icon from "../Icon";
import Image from "../../Image";

const DropdownMenu = ({ item, className, setValue }) => {
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  const handleClick = () => {
    setValue(false);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.group, className)}>
        {/* <button
          className={cn(headerstyles.burger, { [headerstyles.active]: visible })}
          onClick={() => setVisible(!visible)}
        ></button> */}
        {visible && (
          <div className={styles.body}>
            <div className={styles.inner}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <Link
                    className={styles.direction}
                    to="/class01"
                    onClick={() => handleClick()}
                  >
                    <div className={styles.info}>
                      Exciting new game modes coming soon 🔥
                    </div>
                    <div className={styles.preview}>
                      <img
                        srcSet="/images/content/quests-preview.png"
                        src="/images/content/quests-preview.png"
                        alt="Compass"
                      />
                    </div>
                  </Link>
                  
                  <div>
                  <div className={styles.btns}>
              <div className={styles.button}>
                <a href="https://apps.apple.com/us/app/lexicube/id1437594872" target="_blank" rel="noopener noreferrer">
                  <Image
                    srcSet="/images/appstoreIcon.png"
                    srcSetDark="/images/appstoreIcon.png"
                    alt="Download from the Apple App Store"
                  />
                </a>
              </div>
              <div className={styles.button}>
                <a href="https://play.google.com/store/apps/details?id=com.division6.lexicube&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer">
                  <Image
                    srcSet="/images/googleplayIcon.png"
                    srcSetDark="/images/googleplayIcon.png"
                    alt="Download from Google Play"
                  />
                </a>
              </div>
            </div>
                  </div>
                  
                  {/* <div className={styles.menu}>
                    {item.content.menu.map((item, index) => (
                      <NavLink
                        className={styles.box}
                        activeClassName={styles.active}
                        to={item.url}
                        key={index}
                        onClick={() => handleClick()}
                      >
                        <div className={styles.icon}>
                          <img src={item.image} alt={item.title} />
                        </div>
                        <div className={styles.subtitle}>{item.title}</div>
                      </NavLink>
                    ))}
                  </div> */}
                </div>
                <div className={styles.col}>
                  <div className={styles.line}>
                    <div className={styles.cell}>
                      {item.content.links.map(
                        (item, index) =>
                          index === 0 && (
                            <Link
                              className={styles.card}
                              to={item.url}
                              key={index}
                              onClick={() => handleClick()}
                            >
                              <div className={styles.photo}>
                                <img
                                  srcSet={`${item.image2x} 2x`}
                                  src={item.image}
                                  alt={item.status}
                                />
                                <div
                                  className={cn(
                                    {
                                      "status-black": item.category === "black",
                                    },
                                    {
                                      "status-green": item.category === "green",
                                    },
                                    {
                                      "status-red": item.category === "red",
                                    },
                                    {
                                      "status-purple":
                                        item.category === "purple",
                                    },
                                    styles.category
                                  )}
                                >
                                  {item.categoryText}
                                </div>
                              </div>
                              <div className={styles.top}>
                                <div className={styles.user}>
                                  <div className={styles.avatar}>
                                    <img src={item.avatar} alt="Avatar" />
                                  </div>
                                  <div className={styles.details}>
                                    <div className={styles.title}>
                                      {item.title}
                                    </div>
                                    <div className={styles.trainer}>
                                      {item.trainer}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={cn(
                                    {
                                      "status-stroke-green":
                                        item.level === "green",
                                    },
                                    styles.level
                                  )}
                                >
                                  {item.levelText}
                                </div>
                              </div>
                              <div className={styles.content}>
                                {item.content}
                              </div>
                            </Link>
                          )
                      )}
                    </div>
                    <div className={styles.cell}>
                      {item.content.links.map(
                        (item, index) =>
                          index > 0 && (
                            <Link
                              className={styles.item}
                              to={item.url}
                              key={index}
                              onClick={() => handleClick()}
                            >
                              <div className={styles.photo}>
                                <img
                                  srcSet={`${item.image2x} 2x`}
                                  src={item.image}
                                  alt={item.category}
                                />
                              </div>
                              <div className={styles.description}>
                                <div
                                  className={cn(
                                    {
                                      "status-black": item.category === "black",
                                    },
                                    {
                                      "status-green": item.category === "green",
                                    },
                                    {
                                      "status-red": item.category === "red",
                                    },
                                    {
                                      "status-purple":
                                        item.category === "purple",
                                    },
                                    styles.category
                                  )}
                                >
                                  {item.categoryText}
                                </div>
                                <div className={styles.user}>
                                  <div className={styles.avatar}>
                                    <img src={item.avatar} alt="Avatar" />
                                  </div>
                                  <div className={styles.details}>
                                    <div className={styles.title}>
                                      {item.title}
                                    </div>
                                    <div className={styles.trainer}>
                                      {item.trainer}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/*
              // Bottom Area of Mega Menu
              <div className={styles.foot}>
                <div className={styles.stage}>New land owners</div>
                <div className={styles.list}>
                  {item.content.trainer.map((item, index) => (
                    <div className={styles.user} key={index}>
                      <div className={styles.avatar}>
                        <img src={item.avatar} alt="Avatar" />
                      </div>
                      <div className={styles.details}>
                        <div className={styles.title}>{item.title}</div>
                        <div className={styles.trainer}>{item.type}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default withRouter(DropdownMenu);
