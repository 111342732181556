import React from "react";
import styles from "./GameMode01.module.sass";
import Breadcrumbs from "./Breadcrumbs";
import Catalog from "./Catalog";
import Testimonials from "../../components/Testimonials";

const Privacy = () => {
  return (
    <>
      <Breadcrumbs />
      <div>
        <p>asldkfjasfd<br/>asdfsd<br/>asdfadsf</p>
      </div>
    </>
  );
};

export default Privacy;
