import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Book.module.sass";
import Icon from "../../../components/Icon";
import Image from "../../../components/Image";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Lexicube Shapes",
    color: "#45B26B",
    images: "/images/content/user.svg",
    alt: "Lexicube Shapes",
    content: "You've heard of paint by number? Well this is the worlds first paint by spelling game! Once a day, a new shape is available to color in by spelling words. Fill it in all the way for some bonus points!",
  },
  {
    title: "Power-Ups",
    color: "#9757D7",
    images: "/images/content/medal-1.svg",
    alt: "Power-Ups",
    content: "Power-ups can give you an extra boost such as earning extra gold cubes, swapping remaining  letters, or doubling the value of some letters. Buy it once, use it once per round, forever!",
  },
  {
    title: "Multiplayer",
    color: "#3772FF",
    images: "/images/content/lightning.svg",
    alt: "multiplayer",
    content: "Play Lexicube in multiplayer, and go head-to-head agains friends in online turn-based spelling!",
  },
];

const Book = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/goldStack.png"
              srcSetDark="/images/content/goldStack.png"
              src="/images/content/goldStack.png"
              srcDark="/images/content/goldStack.png"
              alt="Pile of gold"
            />
          </div>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={800}
            delay={300}
          >
            <img
              srcSet="/images/content/silverStack.png 2x"
              src="/images/content/silverStack.png"
              alt="Ball"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={1600}
            delay={600}
          >
            <img
              srcSet="/images/content/chestBlock.png 2x"
              src="/images/content/chestBlock.png"
              alt="Ball"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={2400}
            delay={900}
          >
            <img
              srcSet="/images/content/metalBoltBlock.png 2x"
              src="/images/content/metalBoltBlock.png"
              alt="Dumbbells"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            offset={3000}
            delay={1200}
          >
            <img
              srcSet="/images/content/lightningBlock.png 2x"
              src="/images/content/lightningBlock.png"
              alt="Dumbbells"
            />
          </ScrollParallax>
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Join now and start winning.</h2>
          <div className={styles.info}>
          Winning against opponents might be a challenge but winning fun prizes won't be. Win shapes, levels, avatars, powerups and gold cubes. With gold cubes, you can  buy letter-factories, unlock power-ups, and even get more game modes like quests(coming soon).
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.icon}
                  style={{ backgroundColor: x.color }}
                >
                  <img src={x.images} alt={x.alt} />
                </div>
                <div className={styles.details}>
                  <div className={styles.subtitle}>{x.title}</div>
                  <div className={styles.content}>{x.content}</div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className={styles.note}>
            <strong className={styles.green}>Download before Jan 1st</strong> and get 100 free gold{" "}
            {/* <strong className={styles.black}>Fitness Pro</strong> on App Store }
          </div> }
          <div className={styles.btns}>
            <Link className={cn("button", styles.button)} to="/class02-details">
              <span>Get some boosts</span>
              <Icon name="arrow-right" size="10" />
            </Link>
            </div>*/}
        </div>
      </div>
    </div>
  );
};

export default Book;
