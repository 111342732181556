import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Workouts.module.sass";
import Image from "../Image";
import ScrollParallax from "../ScrollParallax";

const items = [
  "No Ads",
  "3 modes of gameplay",
  "Cross-platform (Android & iOS)",
  "Offline play",
  "Fun prizes",
];

const Workouts = () => {
  return (
    <div className={cn("section-bg", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery}>
          <div className={styles.preview}>
            <Image
              srcSet="/images/content/rares-temp.png"
              srcSetDark="/images/content/rares-temp.png"
              src="/images/content/rares-temp.png"
              srcDark="/images/content/rares-temp.png"
              alt="rares"
            />
          </div>
          {/* <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/ball-green-1@2x.png 2x"
              src="/images/content/ball-green-1.png"
              alt="Gloves"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <img
              srcSet="/images/content/apple@2x.png 2x"
              src="/images/content/apple.png"
              alt="Apple"
            />
          </ScrollParallax> */}
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>
            Good clean fun,<br></br>& educational too!
          </h2>
          <div className={styles.info}>
            Lexicube is not only a fun game, it's also a learning tool that can increase brain health.
            </div>
          <ul className={styles.list}>
            {items.map((x, index) => (
              <li className={styles.item} key={index}>
                {x}
              </li>
            ))}
          </ul>
          <div className={styles.btns}>
            <button className={cn("button-stroke", styles.button)}>
            <a href="https://apps.apple.com/us/app/lexicube/id1437594872" style={{color: "inherit"}}>Apple App Store</a>
            </button>
            <div className={cn("button", styles.button)}>
              <a href="https://play.google.com/store/apps/details?id=com.division6.lexicube&hl=en_US&gl=US" style={{color: "inherit"}}>Google Play Store</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workouts;
