const courses = [
  {
    title: "Lexicube Shapes",
    url: "/class01-details",
    image: "/images/content/ShapesAd.png",
    image2x: "/images/content/ShapesAd@2x.png",
    category: "white",
    categoryText: "Lexicube Shapes",
    level: "green",
    levelText: "IAP",
    counterVideo: "7",
    counterUser: "160",
  },
  {
    title: "Multiplayer",
    url: "/class01-details",
    image: "/images/content/MultiplayerAd.png",
    image2x: "/images/content/MultiplayerAd@2x.png",
    category: "white",
    categoryText: "Cross-Platform",
    level: "green",
    levelText: "Free",
    counterVideo: "7",
    counterUser: "160",
  },
  // {
  //   title: "Rares",
  //   url: "/class01-details",
  //   image: "/images/content/card-pic-3.png",
  //   image2x: "/images/content/card-pic-3@2x.png",
  //   category: "white",
  //   categoryText: "Rares",
  //   level: "green",
  //   levelText: "Free",
  //   counterVideo: "7",
  //   counterUser: "160",
  // },
  // {
  //   title: "Letter Royale",
  //   url: "/class01-details",
  //   image: "/images/content/card-pic-4.png",
  //   image2x: "/images/content/card-pic-4@2x.png",
  //   category: "white",
  //   categoryText: "Letter Royale",
  //   level: "green",
  //   levelText: "Free",
  //   counterVideo: "7",
  //   counterUser: "160",
  // },
];

const dksocials = [
  // {
  //   type: "facebook",
  //   url: "https://facebook.com/lexicube",
  // },
  // {
  //   type: "instagram",
  //   url: "https://instagram.com",
  // },
  {
    type: "twitter",
    url: "https://twitter.com/dougkinnison",
  },
  {
    type: "linkedin",
    url: "https://www.linkedin.com/in/dougkinnison/",
  },
  {
    type: "influence",
    url: "https://influence.inc/s/lexicube",
  },
];

const clsocials = [
  {
    type: "facebook",
    url: "https://facebook.com",
  },
  // {
  //   type: "instagram",
  //   url: "https://instagram.com",
  // },
  {
    type: "twitter",
    url: "https://twitter.com",
  },
  {
    type: "linkedin",
    url: "https://www.linkedin.com/in/charles-long-1801a81a/",
  },
];

const jrsocials = [
  {
    type: "facebook",
    url: "https://facebook.com",
  },
  // {
  //   type: "instagram",
  //   url: "https://instagram.com",
  // },
  {
    type: "twitter",
    url: "https://twitter.com",
  },
  {
    type: "linkedin",
    url: "https://www.linkedin.com/in/jeremyrothschild/",
  },
];

const rlusocials = [
  {
    type: "facebook",
    url: "https://facebook.com",
  },
  // {
  //   type: "instagram",
  //   url: "https://instagram.com",
  // },
  {
    type: "twitter",
    url: "https://twitter.com",
  },
  {
    type: "linkedin",
    url: "https://www.linkedin.com/in/dougkinnison/",
  },
];

export const trainersList = [
  {
    title: "Core Team",
    courses: [
      {
        name: "Douglas Kinnison",
        image: "/images/content/doug-headshot.png",
        position: "CEO / Founder",
        courses: courses,
        socials: dksocials,
      },
      {
        name: "Charles Long",
        image: "/images/content/charles-headshot.png",
        position: "Co-founder",
        courses: courses,
        socials: clsocials,
      },
      {
        name: "Robert Lehmann",
        image: "/images/content/robert-headshot.png",
        position: "Lead Developer",
        courses: courses,
        socials: rlusocials,
      },
      // {
      //   name: "Randy Larson",
      //   image: "/images/content/randy-headshot.png",
      //   position: "Markeitng Director",
      //   courses: courses,
      //   socials: socials,
      // }, 
      {
        name: "Jeremy Rothschild",
        image: "/images/content/jeremy-headshot.png",
        position: "Public Relations / Marketing",
        courses: courses,
        socials: jrsocials,
      },      
    ],
  },
  
  
  
];
