import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Hero.module.sass";
import Image from "../../../components/Image";
import ScrollButton from "../../../components/ScrollButton";
import ScrollParallax from "../../../components/ScrollParallax";

const Hero = ({ scrollToRef }) => {
  return (
    <div className={styles.hero}>
      <div className={cn("container", styles.container)}>
        <ScrollParallax className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            IF SCRABBLE AND A RUBIK'S CUBE HAD A BABY...
          </div>
          <h1 className={cn("h1", styles.title)}>
            Lexicube is the most unique word game yet. 
          </h1>
          <div className={styles.text}>
            This 3D word puzzle is free to download and play.  
            Challenge your friends or try to beat your own personal record, or spell as many words as you can to unveil surprise characters and shapes. Every cube is different, no puzzle is the same!

          </div>
          <div className={styles.btns}>
            <div className={styles.button}>
              <a href="https://apps.apple.com/us/app/lexicube/id1437594872" target="_blank" rel="noopener noreferrer">
                <Image
                  srcSet="/images/download-apple-black.svg"
                  srcSetDark="/images/download-apple-black.svg"
                  alt="Download from the Apple App Store"
                />
              </a>
            </div>
            <div className={styles.button}>
              <a href="https://play.google.com/store/apps/details?id=com.division6.lexicube&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer">
                <Image
                  srcSet="/images/download-google.svg"
                  srcSetDark="/images/download-google.svg"
                  alt="Download from Goolge Play"
                />
              </a>
            </div>

            {/* <Link
              className={cn("button-stroke", styles.button)}
              to="/class02-details">
              Apple App Store
            </Link>
            <Link className={cn("button", styles.button)} to="/download">
              Google Play Store
            </Link> */}
          </div>
        </ScrollParallax>
        {/* <ScrollButton
          onScroll={() =>
            scrollToRef.current.scrollIntoView({ behavior: "smooth" })
          }
          className={styles.scroll}
        /> */}
        <div className={styles.gallery}>
        <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <Image
              srcSet="/images/content/hero-img.png"
              srcSetDark="/images/content/hero-img.png"
              // src="/images/content/watch.png"
              // srcDark="/images/content/watch-dark.png"
              alt="Lexicube GamePlay"
            />
          </ScrollParallax>
          {/* <ScrollParallax className={styles.preview} animateIn="fadeInUp">
            <Image
              srcSet="/images/content/NCMap1.png"
              srcSetDark="/images/content/NCMap1.png"
              // src="/images/content/watch.png"
              // srcDark="/images/content/watch-dark.png"
              alt="Watch"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={400}
          >
            <img
              srcSet="/images/content/NCMap2.png"
              // src="/images/content/ball.png"
              alt="Ball"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={600}
          >
            <img
              srcSet="/images/content/NCMap3.png"
              // src="/images/content/bottle.png"
              alt="Bottle"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={900}
          >
            <img
              srcSet="/images/content/NCMap4.png"
              // src="/images/content/ball-black.png"
              alt="Ball black"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={1200}
          >
            <img
              srcSet="/images/content/NCMap5.png"
              // src="/images/content/ball-black.png"
              alt="Ball black"
            />
          </ScrollParallax>
          <ScrollParallax
            className={styles.preview}
            animateIn="fadeInUp"
            delay={1500}
          >
            <img
              srcSet="/images/content/NCMap6.png"
              // src="/images/content/ball-black.png"
              alt="Ball black"
            />
          </ScrollParallax> */}
        </div>
      </div>
    </div>
  );
};

export default Hero;
