import React from "react";
import styles from "./Tools.module.sass";
import Hero from "./Hero";
import Community from "../../components/Community";

const Tools = () => {
  return (
    <>
      <Hero />
      <Community />
    </>
  );
};

export default Tools;
