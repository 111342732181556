import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";

const navLinks = [
  {
    //title: "Download Free",
    content: {
      menu: [
        {
          title: "Apple App Store",
          url: "https://apps.apple.com/us/app/lexicube/id1437594872",
          image: "/images/menu-class.svg",
        },
        {
          title: "Google Play Store",
          url: "https://play.google.com/store/apps/details?id=com.division6.lexicube&hl=en_US&gl=US",
          image: "/images/menu-video.svg",
        },
      ],
      links: [
        {
          title: "New game mode",
          url: "/game-mode01-details",
          image: "/images/content/shapes-panel.png",
          image2x: "/images/content/shapes-panel.png",
          category: "black",
          categoryText: "Uncover new shapes",
          avatar: "/images/logo-orange-grad.png",
          trainer: "Team Lexicube",
          content:
            "In Lexicube Shapes, you can discover new shapes, collect rooms, and win white cubes, gold cubes and more!",
          level: "green",
          levelText: "New Game Mode",
        },
        {
          title: "Single Player",
          url: "/game-mode02-details",
          image: "/images/content/s-player-preview.png",
          image2x: "/images/content/header-pic-2@2x.png",
          category: "red",
          categoryText: "Game Modes",
          avatar: "/images/logo-blue-grad.png",
          trainer: "Team Lexicube",
        },
        {
          title: "Multiplayer",
          url: "/game-mode03-details",
          image: "/images/content/m-player-preview.png",
          image2x: "/images/content/header-pic-3@2x.png",
          category: "purple",
          categoryText: "Game Modes",
          avatar: "/images/logo-green-grad.png",
          trainer: "Team Lexicube",
        },
        {
          title: "Lexicube Quests",
          url: "/game-mode04-details",
          image: "/images/content/quests-preview.png",
          image2x: "/images/content/header-pic-4@2x.png",
          category: "red",
          categoryText: "Coming Soon",
          avatar: "/images/logo-purple-grad.png",
          trainer: "Team Lexicube",
        },
      ],
    },
  },
];

const socials = [
  {
    title: "twitch",
    size: "16",
    url: "https://www.twitch.tv/lexicube",
  },
  {
    title: "facebook",
    size: "16",
    url: "https://www.facebook.com/lexicube/",
  },
  {
    title: "twitter",
    size: "18",
    url: "https://www.twitter.com/lexicube",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/lexicube/",
  },
];

const Headers = () => {
  const [visibleNav, setVisibleNav] = useState(false);

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.pic}
            src="/images/logo-inline.svg"
            srcDark="/images/logo-inline.svg"
            alt="Lexicube"
          />
        </Link>
        <div className={styles.linkbuttons}>
          <div className={styles.socials}>
            {socials.map((x, index) => (
              <a
                className={styles.social}
                href={x.url}
                target="_blank"
                rel="noopener noreferrer"
                key={index}
              >
                <Icon name={x.title} size={x.size} />
              </a>
            ))}
          </div>
          <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
            <nav className={styles.nav}>
              <DropdownMenu
                className={styles.group}
                item={navLinks[0]}
                key={0}
                setValue={setVisibleNav}
              />
            </nav>
          </div>
        </div>

      </div>
    </header>
  );
};

export default Headers;
