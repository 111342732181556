import React from "react";
import styles from "./GameMode02Details.module.sass";
import Hero from "./Hero";
import Interesting from "./Interesting";

const GameMode02Details = () => {
  return (
    <>
      <Hero />
      {/* <Interesting /> */}
    </>
  );
};

export default GameMode02Details;
