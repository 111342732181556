import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
// import Image from "../Image";
import ScrollParallax from "../../components/ScrollParallax";
import styles from "./Offer.module.sass";

const Offer = ({ className }) => {
  return (
    <div className={cn(className, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={cn("stage", styles.stage)}>
          Next feature Release. 🚀
        </div>
        <h2 className={cn("h1", styles.title)}>
          Collectable Rare eggs
        </h2>
        <div className={styles.text}>
          Complete Lexicubes to hatch eggs and collect rare creatures. Your score will determine the rarity of the creatures traits! 
        </div>
        {/* <div className={styles.btns}>
          <Link
            className={cn("button-stroke", styles.button)}
            to="/class02-details">
            Apple App Store
          </Link>
          <Link className={cn("button", styles.button)} to="/download">
            Google Play Store
          </Link>
        </div> */}
        <div className={styles.gallery}>
          <ScrollParallax className={styles.preview} delay={100}>
              <img
                srcSet="/images/content/dino-sm.png 2x"
                src="/images/content/dino-sm.png"
                alt="dino"
              />
            </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp" offset={300}  delay={300}>
            <img
              srcSet="/images/content/skull-sm.png 2x"
              src="/images/content/skull-sm.png"
              alt="skull"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp"  delay={600}>
            <img
              srcSet="/images/content/flowers-sm.png 2x"
              src="/images/content/flowers-sm.png"
              alt="flowers"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp"  delay={900}>
            <img
              srcSet="/images/content/metal-sm.png 2x"
              src="/images/content/metal-sm.png"
              alt="metal"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp" delay={1200}>
            <img
              srcSet="/images/content/grass-sm.png 2x"
              src="/images/content/grass-sm.png"
              alt="grass"
            />
          </ScrollParallax>
          <ScrollParallax className={styles.preview} animateIn="fadeInUp" delay={1500}>
            <img
              srcSet="/images/content/lightning-sm.png 2x"
              src="/images/content/lightning-sm.png"
              alt="lightning"
            />
          </ScrollParallax>
          </div>
      </div>
    </div>
  );
};

export default Offer;
