import React from "react";
import styles from "./GameMode01.module.sass";
import Breadcrumbs from "./Breadcrumbs";
import Catalog from "./Catalog";
import Testimonials from "../../components/Testimonials";

const Terms = () => {
  return (
    <>
      <Breadcrumbs />
      <div>asldkfjasfd<br/>asdfsd<br/>asdfadsf</div>
    </>
  );
};

export default Terms;
